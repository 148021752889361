import React from "react";
import { Asset } from "contentful";
import cn from "classnames";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import useMediaQuery, {
  LARGER_THAN_MOBILE,
  MOBILE,
} from "storefront/hooks/useMediaQuery";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import ButtonLink from "storefront/components/ButtonLink";
import Title1 from "storefront/components/Title1";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import Body from "storefront/components/Body";
import AutoplayVideo from "storefront/components/AutoplayVideo";
import useAnalytics from "storefront/hooks/useAnalytics";
import moduleClicked from "storefront/Analytics/EventCreators/moduleClicked";

import useAuthentication from "storefront/hooks/useAuthentication";
import { HeroLoading } from "storefront/components/Homepage/ContentfulHomepage/LoadingHomepageContent";
import css from "./BigVideoModule.module.scss";

export const BIG_VIDEO = "searchCta" as const;

type Fields = {
  callsToActions: Array<[string, string]>;
  desktopPoster: Asset;
  desktopVideo: Asset;
  headline: string;
  mobilePoster: Asset;
  mobileVideo: Asset;
  name: string;
  subheadline: string;
  mobileAction: string;
};

type WrapperProps = {
  children: React.ReactNode;
  href: string;
  isMobile: boolean | null;
  onClick?: () => void;
  newHomepageEnabled: boolean;
};

const Wrapper = ({
  children,
  href,
  isMobile,
  newHomepageEnabled,
  onClick,
}: WrapperProps) =>
  isMobile && newHomepageEnabled ? (
    <a href={href} className={css.link} onClick={onClick}>
      {children}
    </a>
  ) : (
    <>{children}</>
  );

// NOTE: We repurposed the Search CTA content type because we are over our content type limit for
// our Contentful plan. Contentful does not allow changing the content type id ("searchCta") and we
// cannot delete old content types and re-create them (we have 90 content types on a plan that
// allows for 48 content types). [Evan 2022-10-13]
export type BigVideoModuleEntry = TypedEntry<typeof BIG_VIDEO, Fields>;

type Props = {
  entry: BigVideoModuleEntry;
  from: string;
  position: number;
  className?: string;
  onClick?: () => void;
};

const BigVideoModule = ({
  entry,
  from,
  position,
  className,
  onClick,
}: Props) => {
  const {
    headline,
    subheadline,
    callsToActions,
    desktopVideo,
    desktopPoster,
    mobileVideo,
    mobilePoster,
    name,
  } = entry.fields;
  const { track } = useAnalytics();
  const isMobile = useMediaQuery(MOBILE);
  const auth = useAuthentication();
  const newHomepageFlag =
    auth.type === "Authenticated"
      ? Feature.NEW_HOMEPAGE_LOGGED_IN
      : Feature.NEW_HOMEPAGE_LOGGED_OUT;
  const newHomepageEnabledResource = useFeatureFlag(newHomepageFlag);

  const trackModuleClicked = (itemName: string, itemPosition: number) => {
    track(
      moduleClicked({
        from,
        itemName,
        itemNameContentful: itemName,
        itemPosition,
        itemType: "CTA",
        moduleName: headline,
        moduleNameContentful: name,
        modulePosition: position,
        moduleType: "Big Video Module",
      }),
    );
  };

  if (
    newHomepageEnabledResource.type !== "Completed" ||
    auth.type === "Loading"
  )
    return <HeroLoading />;

  const newHomepageEnabled = newHomepageEnabledResource.value;

  return (
    <Wrapper
      href={entry.fields.mobileAction}
      isMobile={isMobile}
      newHomepageEnabled={newHomepageEnabledResource.value}
    >
      <section
        className={cn(css.root, className, {
          [css.legacyStyle]: !newHomepageEnabled,
        })}
      >
        {headline ? (
          <Title1
            className={cn(css.headline, {
              [css.legacyStyle]: !newHomepageEnabled,
            })}
          >
            {headline}
          </Title1>
        ) : null}

        {subheadline ? (
          <Body
            className={cn(css.subheadline, {
              [css.legacyStyle]: !newHomepageEnabled,
            })}
          >
            {subheadline}
          </Body>
        ) : null}

        {isMobile && newHomepageEnabled ? null : (
          <div className={css.actions}>
            {callsToActions.map(([label, path], index) => (
              <ButtonLink
                key={path}
                size="large"
                variant="primary"
                href={path}
                className={css.action}
                onClick={() => {
                  trackModuleClicked(label, index);
                  if (onClick) onClick();
                }}
              >
                {label}
              </ButtonLink>
            ))}
          </div>
        )}

        <AutoplayVideo
          className={css.video}
          sources={[
            {
              src: mobileVideo.fields.file.url,
              type: mobileVideo.fields.file.contentType,
              poster: mobilePoster.fields.file.url,
              media: MOBILE,
              description: mobilePoster.fields.description,
            },
            {
              src: desktopVideo.fields.file.url,
              type: desktopVideo.fields.file.contentType,
              poster: desktopPoster.fields.file.url,
              media: LARGER_THAN_MOBILE,
              description: desktopPoster.fields.description,
            },
          ]}
          fallback={{
            src: desktopVideo.fields.file.url,
            type: desktopVideo.fields.file.contentType,
            poster: desktopPoster.fields.file.url,
            description: desktopPoster.fields.description,
          }}
        />
      </section>
    </Wrapper>
  );
};

export default BigVideoModule;
