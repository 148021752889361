import React from "react";
import cn from "classnames";

import styles from "./LoadingHomepageContent.module.scss";

export const HeroLoading = () => (
  <div className={cn(styles.heroLoading, styles.shimmer)} />
);

const LoadingHomepageContent = () => (
  <>
    <HeroLoading />
    <div className={cn(styles.tickerLoading, styles.shimmer)} />
    <div className={styles.gridLoading}>
      <div className={cn(styles.agcCollectionLoading, styles.shimmer)} />
      <div className={cn(styles.agcCollectionLoading, styles.shimmer)} />
      <div className={cn(styles.agcCollectionLoading, styles.shimmer)} />
      <div className={cn(styles.agcCollectionLoading, styles.shimmer)} />
      <div className={cn(styles.agcCollectionLoading, styles.shimmer)} />
      <div className={cn(styles.agcCollectionLoading, styles.shimmer)} />
    </div>
  </>
);

export default LoadingHomepageContent;
