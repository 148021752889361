import { Authentication } from "storefront/Authentication";
import getCollectionsForUser, {
  PersonalizedCollections,
} from "storefront/GrailedAPI/v1/Collections/getCollectionsForUser";
import useData, { DataResource } from "storefront/hooks/data/useData";

export default function useCollectionsForUser(
  auth: Authentication | undefined,
): DataResource<PersonalizedCollections> {
  return useData(getCollectionsForUser, {
    args:
      auth?.type === "Authenticated" ? [{ userId: auth.user.id }] : undefined,
    shouldFetch: !!auth && auth.type !== "Loading",
  });
}
