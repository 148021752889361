import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselNavDots,
  CarouselNavNext,
  CarouselNavPrev,
  CarouselSlide,
} from "storefront/components/CarouselNext";
import useModuleAnalytics from "storefront/hooks/useModuleAnalytics";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import BigVideoModule, {
  BIG_VIDEO,
  BigVideoModuleEntry,
} from "../BigVideoModule";
import HeroCarouselContentItem, {
  HERO_CAROUSEL_CONTENT_ITEM,
  HeroCarouselContentItemEntry,
} from "../HeroCarouselContentItem";

import styles from "./HeroCarousel.module.scss";

export const HERO_CAROUSEL = "searchCtaSuggestion" as const;

export type HeroCarouselEntry = TypedEntry<
  typeof HERO_CAROUSEL,
  {
    name: string;
    autoScroll: boolean;
    contentItems: Array<BigVideoModuleEntry | HeroCarouselContentItemEntry>;
  }
>;

type SlideComponentProps = {
  entry: BigVideoModuleEntry | HeroCarouselContentItemEntry;
  from: string;
  position: number;
  onClick?: () => void;
};

const SlideComponent = ({
  entry,
  from,
  position,
  onClick,
}: SlideComponentProps) => {
  const { contentType } = entry;

  switch (contentType) {
    case HERO_CAROUSEL_CONTENT_ITEM:
      return (
        <CarouselSlide>
          <HeroCarouselContentItem
            entry={entry}
            onClick={onClick}
            className={styles.slide}
          />
        </CarouselSlide>
      );
    case BIG_VIDEO:
      return (
        <CarouselSlide>
          <BigVideoModule
            entry={entry}
            from={from}
            position={position}
            className={styles.slide}
            onClick={onClick}
          />
        </CarouselSlide>
      );
    default:
      ((_: never): void => {})(contentType);
      return null;
  }
};

type Props = {
  entry: HeroCarouselEntry;
  from: string;
  position: number;
};

const HeroCarousel = ({ entry, from, position }: Props) => {
  const analytics = useModuleAnalytics({
    name: entry.fields.name,
    type: "Hero Carousel",
    position,
    from,
  });

  const slides = entry.fields.contentItems;
  const { autoScroll } = entry.fields;

  return (
    <Carousel autoscroll={autoScroll} loop ref={analytics?.ref}>
      <CarouselContent>
        {slides.map((slide) => {
          return (
            <SlideComponent
              entry={slide}
              from={from}
              position={position}
              onClick={analytics?.onClick}
              key={entry.contentType + slide.sys.id}
            />
          );
        })}
      </CarouselContent>
      {slides.length > 1 ? (
        <>
          <CarouselNavNext />
          <CarouselNavPrev />
          <CarouselNavDots />
        </>
      ) : null}
    </Carousel>
  );
};

export default HeroCarousel;
