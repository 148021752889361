import useAnalytics from "storefront/hooks/useAnalytics";
import useCallbackOnView from "storefront/hooks/useCallbackOnView";
import {
  homepageCollectionViewed,
  homepageModuleViewed,
} from "storefront/Analytics/EventCreators/homepageContentViewed";
import {
  homepageCollectionClicked,
  homepageModuleClicked,
} from "storefront/Analytics/EventCreators/homepageContentClicked";
import { Tracker } from "storefront/Analytics/Tracker";
import { CollectionDisplay } from "storefront/Collection";

type ContentParams = {
  position: number;
  from: string;
};

type ModuleParams = ContentParams & {
  name: string;
  type: string;
};

type CollectionParams = ContentParams & {
  collection: CollectionDisplay;
  algorithmId: string;
};

type Analytics = {
  ref: (node?: Element | null) => void;
  onClick: () => void;
};

function isCollection(
  params: ModuleParams | CollectionParams,
): params is CollectionParams {
  return "collection" in params;
}

function trackHomepageContentViewed(
  track: Tracker["track"],
  params: ModuleParams | CollectionParams,
) {
  if (isCollection(params)) {
    const { collection, algorithmId, position } = params;
    track(homepageCollectionViewed(collection, algorithmId, position));
  } else {
    const { name, type, position } = params;
    track(homepageModuleViewed(name, type, position));
  }
}

function trackHomepageContentClicked(
  track: Tracker["track"],
  params: ModuleParams | CollectionParams,
) {
  if (isCollection(params)) {
    const { collection, algorithmId, position } = params;
    track(homepageCollectionClicked(collection, algorithmId, position));
  } else {
    const { name, type, position } = params;
    track(homepageModuleClicked(name, type, position));
  }
}

function useModuleAnalytics(
  params: ModuleParams | CollectionParams,
): Analytics | undefined {
  const { track } = useAnalytics();

  const ref = useCallbackOnView(
    () => {
      if (params.from === "homepage") trackHomepageContentViewed(track, params);
    },
    true,
    0.9,
  );

  const onClick = () => {
    if (params.from === "homepage") trackHomepageContentClicked(track, params);
  };

  return { ref, onClick };
}

export default useModuleAnalytics;
