import React from "react";
import { PageType } from "storefront/Analytics/Event";
import { PersonalizedCollections } from "storefront/GrailedAPI/v1/Collections/getCollectionsForUser";
import CollectionModule from "storefront/components/CollectionModule";
import styles from "./index.module.scss";

type Props = {
  personalizedCollections: PersonalizedCollections;
  startingPosition: number;
  pageType: PageType;
};

const PersonalizedCollectionGrid = ({
  personalizedCollections,
  startingPosition,
  pageType,
}: Props) => {
  const { collections, algorithmId } = personalizedCollections;

  return (
    <div className={styles.collections}>
      {collections.map((collection, index) => (
        <CollectionModule
          collection={collection}
          position={startingPosition + index}
          pageType={pageType}
          algorithmId={algorithmId}
          key={`${collection.id}-${collection.externalId}-${collection.lastRefreshedAt}`}
        />
      ))}
    </div>
  );
};

export default PersonalizedCollectionGrid;
