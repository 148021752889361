import React from "react";
import cn from "classnames";
import { Asset } from "contentful";
import useMediaQuery, { MOBILE } from "storefront/hooks/useMediaQuery";
import { TypedEntry } from "storefront/Contentful/TypedEntry";
import ButtonLink from "storefront/components/ButtonLink";
import Body from "storefront/components/Body";
import Title1 from "storefront/components/Title1";
import VideoOrImage from "./VideoOrImage";

import styles from "./HeroCarouselContentItem.module.scss";

export const HERO_CAROUSEL_CONTENT_ITEM = "moduleValueProps" as const;

export type HeroCarouselContentItemEntry = TypedEntry<
  typeof HERO_CAROUSEL_CONTENT_ITEM,
  {
    name: string;
    tag: string;
    title: string;
    url: string;
    desktopCtaText: string;
    desktopImage: Asset;
    desktopVideo?: Asset;
    mobileImage: Asset;
    mobileVideo?: Asset;
  }
>;

const MobileTapWrapper = ({
  children,
  href,
  isMobile,
  onClick,
}: {
  children: React.ReactNode;
  href: string;
  isMobile: boolean | null;
  onClick?: () => void;
}) =>
  isMobile ? (
    <a href={href} className={styles.link} onClick={onClick}>
      {children}
    </a>
  ) : (
    <>{children}</>
  );

type Props = {
  entry: HeroCarouselContentItemEntry;
  onClick?: () => void;
  className?: string;
};

const HeroCarouselContentItem = ({ entry, onClick, className }: Props) => {
  const isMobile = useMediaQuery(MOBILE);
  const {
    url,
    tag,
    title,
    desktopImage,
    desktopVideo,
    mobileImage,
    mobileVideo,
    desktopCtaText,
  } = entry.fields;

  return (
    <MobileTapWrapper href={url} isMobile={isMobile} onClick={onClick}>
      <section className={cn(styles.root, className)}>
        <Body className={styles.tag}>{tag}</Body>
        <Title1 className={styles.title}>{title}</Title1>
        {isMobile ? null : (
          <ButtonLink
            size="large"
            variant="primary"
            href={url}
            className={styles.action}
            onClick={onClick}
          >
            {desktopCtaText}
          </ButtonLink>
        )}
        <VideoOrImage
          mobileVideo={mobileVideo}
          mobileImage={mobileImage}
          desktopVideo={desktopVideo}
          desktopImage={desktopImage}
          className={styles.videoOrImage}
        />
      </section>
    </MobileTapWrapper>
  );
};

export default HeroCarouselContentItem;
