import React from "react";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import {
  EmbeddedFeature,
  ModuleEntry,
  EMBEDDED_FEATURE,
  PERSONALIZED_COLLECTION_GRID,
  TICKER,
} from "storefront/Contentful/types";
import { getId } from "storefront/Contentful/Entry";
import useAuthentication from "storefront/hooks/useAuthentication";
import useCollectionsForUser from "storefront/hooks/useCollectionsForUser";
import { PersonalizedCollections } from "storefront/GrailedAPI/v1/Collections/getCollectionsForUser";
import LoadingHomepageContent from "storefront/components/Homepage/ContentfulHomepage/LoadingHomepageContent";
import Ticker from "storefront/components/Ticker";
import PersonalizedCollectionGrid from "storefront/components/PersonalizedCollectionGrid";
import AlertBanner, { ALERT_BANNER } from "storefront/components/AlertBanner";
import BigVideoModule, { BIG_VIDEO } from "../BigVideoModule";
import CategoryGrid, { CATEGORY_GRID } from "../CategoryGrid";
import DCOBlock, { DCO_BLOCK } from "../DCOBlock";
import DCOEntryPoint, { DCO_ENTRY_POINT } from "../DCOEntryPoint";
import DesignerGrid, { DESIGNER_GRID } from "../DesignerGrid";
import DesignersForYou, { DESIGNERS_FOR_YOU } from "../DesignersForYou";
import DiscoverDailyCarousel, {
  DISCOVER_DAILY_CAROUSEL,
} from "../DiscoverDailyCarousel";
import FeaturedCapsules, { FEATURED_CAPSULES } from "../FeaturedCapsules";
import FeaturedContentModule from "../FeaturedContentModule";
import { FEATURED_CONTENT } from "../FeaturedContentModule/FeaturedContentModuleEntry";
import FeedCarousel, { FEED_CAROUSEL } from "../FeedCarousel";
import FeedGrid, { FEED_GRID } from "../FeedGrid";
import FullWidthCTA, { FULL_WIDTH_CTA } from "../FullWidthCTA";
import GuideScreen from "../Guide/GuideScreen";
import { GUIDE_SCREEN } from "../Guide/types";
import ListingsFromFeed, { LISTINGS_FROM_FEED } from "../ListingsFromFeed";
import RecentlyViewed, { RECENTLY_VIEWED } from "../RecentlyViewed";
import VisualFilters, { VISUAL_FILTERS } from "../VisualFilters";
import ContentfulErrorBoundary from "../ContentfulModuleWrapper/ContentfulErrorBoundary";
import HeroCarousel, { HERO_CAROUSEL } from "../HeroCarousel";

type Props = {
  modules: Array<ModuleEntry>;
  from: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
};

type EmbeddedWrapperProps = {
  entry: EmbeddedFeature;
  position: number;
  pageType: PageType;
  personalizedContent?: PersonalizedCollections;
};

const EmbeddedWrapper = ({
  entry,
  position,
  pageType,
  personalizedContent,
}: EmbeddedWrapperProps) => {
  const { fields } = entry;
  const { moduleId } = fields;

  switch (moduleId) {
    case TICKER:
      return <Ticker text="🔥 Grails for You" />;
    case PERSONALIZED_COLLECTION_GRID:
      return (
        <>
          {personalizedContent ? (
            <PersonalizedCollectionGrid
              personalizedCollections={personalizedContent}
              startingPosition={position}
              pageType={pageType}
            />
          ) : null}
        </>
      );
    default:
      ((_: never): void => {})(moduleId);
      return null;
  }
};

type ModuleComponentProps = {
  entry?: ModuleEntry;
  position?: number;
  from?: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
  personalizedContent?: PersonalizedCollections;
};

const contentTypeToComponent: Record<
  string,
  React.ComponentType<ModuleComponentProps>
> = {
  [ALERT_BANNER]: AlertBanner,
  [BIG_VIDEO]: BigVideoModule,
  [CATEGORY_GRID]: CategoryGrid,
  [DCO_BLOCK]: DCOBlock,
  [DCO_ENTRY_POINT]: DCOEntryPoint,
  [DESIGNER_GRID]: DesignerGrid,
  [DESIGNERS_FOR_YOU]: DesignersForYou,
  [DISCOVER_DAILY_CAROUSEL]: DiscoverDailyCarousel,
  [EMBEDDED_FEATURE]: EmbeddedWrapper,
  [FEATURED_CAPSULES]: FeaturedCapsules,
  [FEATURED_CONTENT]: FeaturedContentModule,
  [FEED_CAROUSEL]: FeedCarousel,
  [FEED_GRID]: FeedGrid,
  [FULL_WIDTH_CTA]: FullWidthCTA,
  [GUIDE_SCREEN]: GuideScreen,
  [HERO_CAROUSEL]: HeroCarousel,
  [LISTINGS_FROM_FEED]: ListingsFromFeed,
  [RECENTLY_VIEWED]: RecentlyViewed,
  [VISUAL_FILTERS]: VisualFilters,
};

function isPersonalizedContent(entry: ModuleEntry): boolean {
  return (
    entry.contentType === EMBEDDED_FEATURE &&
    entry.fields.moduleId === PERSONALIZED_COLLECTION_GRID
  );
}

function isTicker(entry: ModuleEntry): boolean {
  return (
    entry.contentType === EMBEDDED_FEATURE && entry.fields.moduleId === TICKER
  );
}

function positionIncrementByEntry(
  entry: ModuleEntry,
  personalizedContent?: PersonalizedCollections,
): number {
  if (isPersonalizedContent(entry) && !!personalizedContent) {
    return personalizedContent?.collections?.length || 0;
  }

  if (isTicker(entry)) return 0;

  return 1;
}

const Modules = ({
  modules,
  from,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: Props) => {
  const auth = useAuthentication();

  const withPersonalizedContent = modules.some((entry) =>
    isPersonalizedContent(entry),
  );

  const personalizedCollectionsResource = useCollectionsForUser(
    withPersonalizedContent ? auth : undefined,
  );

  if (
    auth.type === "Loading" ||
    (withPersonalizedContent &&
      personalizedCollectionsResource.type === "Loading")
  )
    return <LoadingHomepageContent />;

  let position = 0;

  return (
    <>
      {modules.map((entry) => {
        const ModuleComponent = contentTypeToComponent[entry.contentType];

        if (!ModuleComponent) return null;

        const modulePosition = position;

        const personalizedContent =
          isPersonalizedContent(entry) &&
          personalizedCollectionsResource.type === "Completed"
            ? personalizedCollectionsResource.value
            : undefined;

        position += positionIncrementByEntry(entry, personalizedContent);

        return (
          <ContentfulErrorBoundary key={getId(entry)}>
            <ModuleComponent
              key={getId(entry)}
              entry={entry}
              position={modulePosition}
              from={from}
              pageType={pageType}
              pageTypeIdentifier={pageTypeIdentifier}
              pageTypeName={pageTypeName}
              personalizedContent={personalizedContent}
            />
          </ContentfulErrorBoundary>
        );
      })}
    </>
  );
};

export default Modules;
