import React from "react";

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ContentfulErrorBoundary extends React.Component<
  Props,
  State
> {
  state = { hasError: false };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, info);
  }

  static getDerivedStateFromError(): State {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
