import React from "react";
import { Id } from "storefront/lib/Id";
import { PageType } from "storefront/Analytics/Event";
import { WithContentfulProps } from "storefront/Contentful/withContentful";
import LoadingHomepageContent from "storefront/components/Homepage/ContentfulHomepage/LoadingHomepageContent";
import { PageEntry } from "storefront/Contentful/types";
import Modules from "./Modules";

type Props = {
  baseClassName: string;
  from: string;
  pageType?: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
} & WithContentfulProps<PageEntry>;

const HOMEPAGE: PageType = "homepage" as const;

/**
 * @name ContentfulPageWrapper
 * @description Renders a generic Contentful Modules Wrapper
 * that can be used on different pages
 * @param {?PageEntry} props.entry - a contentful entry that has the added contentType
 * @param {?ContentfulError} props.error - a contentful error
 */
const ContentfulPageWrapper = ({
  entry,
  error,
  baseClassName,
  from,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
}: Props) => {
  if (entry) {
    const { modules } = entry.fields;

    return (
      <div className={`${baseClassName}--Modules`}>
        <Modules
          modules={modules}
          from={from}
          pageType={pageType}
          pageTypeIdentifier={pageTypeIdentifier}
          pageTypeName={pageTypeName}
        />
      </div>
    );
  }

  if (error) return <></>;

  return (
    <>
      {pageType === HOMEPAGE ? (
        <LoadingHomepageContent />
      ) : (
        <>
          <div className={`${baseClassName}--Modules`}>
            <div className="Module--FeaturedBlock _loading" />
          </div>
          <div className="Homepage--Modules-Loading">
            <h3 className="-title">...</h3>
            <div className="-container">
              <div className="-item _loading" />
              <div className="-item _loading" />
              <div className="-item _loading" />
              <div className="-item _loading" />
              <div className="-item _loading" />
              <div className="-item _loading" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContentfulPageWrapper;
